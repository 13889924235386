define('ember-moment/utils/is-descriptor', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var typeOf = Ember['default'].typeOf;
  var Descriptor = Ember['default'].Descriptor;

  // credit: https://github.com/cibernox/ember-cpm/blob/master/addon/utils.js#L17-L20
  function isDescriptor(propertyName) {
    var meta = Ember['default'].meta(this);

    if (meta && meta.descs && meta.descs[propertyName]) {
      return true;
    }

    var prop = this[propertyName];
    return typeOf(prop) === 'object' && (prop.constructor === Descriptor || // Ember < 1.11
    prop.isDescriptor); // Ember >= 1.11.0
  }

  exports['default'] = isDescriptor;

});