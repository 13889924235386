define('ember-moment/computeds/duration', ['exports', 'ember', 'moment', 'ember-moment/utils/is-descriptor'], function (exports, Ember, moment, isDescriptor) {

  'use strict';



  exports['default'] = computedDuration;
  var get = Ember['default'].get;
  var emberComputed = Ember['default'].computed;

  function computedDuration(val, maybeUnits) {
    var numArgs = arguments.length;
    var args = [val];

    var computed = emberComputed(val, function () {
      var momentArgs = undefined,
          desc = undefined,
          input = undefined;

      momentArgs = [get(this, val)];

      if (numArgs > 1) {
        desc = isDescriptor['default'].call(this, maybeUnits);
        input = desc ? get(this, maybeUnits) : maybeUnits;

        if (desc && computed._dependentKeys.indexOf(maybeUnits) === -1) {
          computed.property(maybeUnits);
        }

        momentArgs.push(input);
      }

      return moment['default'].duration.apply(this, momentArgs).humanize();
    });

    return computed.property.apply(computed, args).readOnly();
  }

});