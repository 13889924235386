define('ember-moment/computeds/format', ['exports', 'ember', 'ember-moment/utils/is-descriptor', 'moment'], function (exports, Ember, isDescriptor, moment) {

  'use strict';



  exports['default'] = computedMoment;
  var computed = Ember['default'].computed;
  var get = Ember['default'].get;
  var assert = Ember['default'].assert;

  var a_slice = Array.prototype.slice;
  function computedMoment(date, outputFormat, maybeInputFormat) {
    assert('More than one argument passed into moment computed', arguments.length > 1);

    var args = a_slice.call(arguments);
    var result = undefined;
    args.shift();

    return result = computed(date, function () {
      var _this = this;

      var momentArgs = [get(this, date)];

      var propertyValues = args.map(function (arg) {
        var desc = isDescriptor['default'].call(_this, arg);
        if (desc && result._dependentKeys.indexOf(arg) === -1) {
          result.property(arg);
        }

        return desc ? get(_this, arg) : arg;
      });

      outputFormat = propertyValues[0];

      if (propertyValues.length > 1) {
        maybeInputFormat = propertyValues[1];
        momentArgs.push(maybeInputFormat);
      }

      return moment['default'].apply(this, momentArgs).format(outputFormat);
    }).readOnly();
  }

});