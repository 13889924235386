define('ember-moment/helpers/moment-duration', ['exports', 'moment'], function (exports, moment) {

  'use strict';

  function durationHelper(params) {
    var length = params.length;

    if (length === 0 || length > 2) {
      throw new TypeError('Invalid Number of arguments, expected 1 or 2');
    }

    return moment['default'].duration.apply(this, params).humanize();
  }

  exports['default'] = durationHelper;

});