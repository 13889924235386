define('ember-drag-drop/components/draggable-object', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    tagName: "div",
    overrideClass: 'draggable-object',
    classNameBindings: ["isDraggingObject:is-dragging-object:", 'overrideClass'],
    attributeBindings: ['draggable'],
    isDraggable: true,
    title: Ember['default'].computed.alias('content.title'),

    draggable: Ember['default'].computed('isDraggable', function () {
      var isDraggable = this.get('isDraggable');

      if (isDraggable) {
        return true;
      } else {
        return null;
      }
    }),

    dragStart: function dragStart(event) {

      var dataTransfer = event.dataTransfer;

      var obj = this.get('content');
      var id = this.get('coordinator').setObject(obj, { source: this });

      dataTransfer.setData('Text', id);

      if (obj) {
        Ember['default'].set(obj, 'isDraggingObject', true);
      }
      this.set('isDraggingObject', true);
      this.sendAction('dragStartAction', obj);
    },

    dragEnd: function dragEnd() {
      var obj = this.get('content');

      if (obj) {
        Ember['default'].set(obj, 'isDraggingObject', false);
      }
      this.set('isDraggingObject', false);
      this.sendAction('dragEndAction', obj);
    },

    actions: {
      selectForDrag: function selectForDrag() {
        var obj = this.get('content');
        var hashId = this.get('coordinator').setObject(obj, { source: this });
        this.get('coordinator').set("clickedId", hashId);
      }
    }
  });

});