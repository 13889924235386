define('ember-moment/utils/make-bound-helper', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = function (helper) {
    if (Ember['default'].Helper && Ember['default'].Helper.detect(helper)) {
      return helper;
    }

    return Ember['default'].HTMLBars.makeBoundHelper(helper);
  }

});