define('ember-django-adapter/serializers/drf', ['exports', 'ember-data', 'ember'], function (exports, DS, Ember) {

  'use strict';

  exports['default'] = DS['default'].JSONSerializer.extend({
    // Remove this in our 2.0 release.
    isNewSerializerAPI: true,

    /**
     * Returns the resource's relationships formatted as a JSON-API "relationships object".
     *
     * http://jsonapi.org/format/#document-resource-object-relationships
     *
     * This version adds a 'links'hash with relationship urls before invoking the
     * JSONSerializer's version.
     *
     * @method extractRelationships
     * @param {Object} modelClass
     * @param {Object} resourceHash
     * @return {Object}
     */
    extractRelationships: function extractRelationships(modelClass, resourceHash) {
      if (!resourceHash.hasOwnProperty('links')) {
        resourceHash['links'] = {};
      }

      modelClass.eachRelationship(function (key, relationshipMeta) {
        var payloadRelKey = this.keyForRelationship(key);

        if (!resourceHash.hasOwnProperty(payloadRelKey)) {
          return;
        }

        if (relationshipMeta.kind === 'hasMany' || relationshipMeta.kind === 'belongsTo') {
          // Matches strings starting with: https://, http://, //, /
          var payloadRel = resourceHash[payloadRelKey];
          if (!Ember['default'].isNone(payloadRel) && !Ember['default'].isNone(payloadRel.match) && typeof payloadRel.match === 'function' && payloadRel.match(/^((https?:)?\/\/|\/)\w/)) {
            resourceHash['links'][key] = resourceHash[payloadRelKey];
            delete resourceHash[payloadRelKey];
          }
        }
      }, this);

      return this._super(modelClass, resourceHash);
    },

    /**
     *  Returns the number extracted from the page number query param of
     *  a `url`. `null` is returned when the page number query param
     *  isn't present in the url. `null` is also returned when `url` is
     *  `null`.
     *
     * @method extractPageNumber
     * @private
     * @param {String} url
     * @return {Number} page number
     */
    extractPageNumber: function extractPageNumber(url) {
      var match = /.*?[\?&]page=(\d+).*?/.exec(url);
      if (match) {
        return Number(match[1]).valueOf();
      }
      return null;
    },

    /**
     * Normalizes server responses for array or list data using the JSONSerializer's version
     * of this function.
     *
     * If the payload has a results property, all properties that aren't in the results
     * are added to the 'meta' hash so that Ember Data can use these properties for metadata.
     * The next and previous pagination URLs are parsed to make it easier to paginate data
     * in applications.
     *
     * @method normalizeArrayResponse
     * @param {DS.Store} store
     * @param {DS.Model} primaryModelClass
     * @param {Object} payload
     * @param {String|Number} id
     * @param {String} requestType
     * @return {Object} JSON-API Document
     */
    normalizeArrayResponse: function normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
      if (!Ember['default'].isNone(payload) && payload.hasOwnProperty('results')) {
        // Move DRF metadata to the meta hash.
        var modifiedPayload = JSON.parse(JSON.stringify(payload.results));
        delete payload.results;
        modifiedPayload['meta'] = JSON.parse(JSON.stringify(payload));

        // The next and previous pagination URLs are parsed to make it easier to paginate data in applications.
        if (!Ember['default'].isNone(modifiedPayload.meta['next'])) {
          modifiedPayload.meta['next'] = this.extractPageNumber(modifiedPayload.meta['next']);
        }
        if (!Ember['default'].isNone(modifiedPayload.meta['previous'])) {
          modifiedPayload.meta['previous'] = this.extractPageNumber(modifiedPayload.meta['previous']);
        }
        return this._super(store, primaryModelClass, modifiedPayload, id, requestType);
      }

      return this._super(store, primaryModelClass, payload, id, requestType);
    },

    /**
     * You can use this method to customize how a serialized record is
     * added to the complete JSON hash to be sent to the server. By
     * default the JSON Serializer does not namespace the payload and
     * just sends the raw serialized JSON object.
     *
     * If your server expects namespaced keys, you should consider using
     * the RESTSerializer.  Otherwise you can override this method to
     * customize how the record is added to the hash.
     *
     * For example, your server may expect underscored root objects.
     *
     * @method serializeIntoHash
     * @param {Object} hash
     * @param {subclass of DS.Model} type
     * @param {DS.Snapshot} snapshot
     * @param {Object} options
     */
    serializeIntoHash: function serializeIntoHash(hash, type, snapshot, options) {
      Ember['default'].merge(hash, this.serialize(snapshot, options));
    },

    /**
     * `keyForAttribute` can be used to define rules for how to convert
     * an attribute name in your model to a key in your JSON.
     *
     * @method keyForAttribute
     * @param {String} key
     * @return {String} normalized key
     */
    keyForAttribute: function keyForAttribute(key) {
      return Ember['default'].String.decamelize(key);
    },

    /**
     * `keyForRelationship` can be used to define a custom key when
     * serializing relationship properties. By default `JSONSerializer`
     * does not provide an implementation of this method.
     *
     * @method keyForRelationship
     * @param {String} key
     * @return {String} normalized key
     */
    keyForRelationship: function keyForRelationship(key) {
      return Ember['default'].String.decamelize(key);
    }
  });

});