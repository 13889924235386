define('ember-moment/helpers/moment-from-now', ['exports', 'ember', 'moment'], function (exports, Ember, moment) {

  'use strict';

  var runLater = Ember['default'].run.later;
  var helperFactory = function helperFactory(cb) {
    var ago = undefined;

    if (Ember['default'].Helper) {
      ago = Ember['default'].Helper.extend({
        compute: function compute(params, hash) {
          if (typeof cb === 'function') {
            cb();
          }
          if (params.length === 0) {
            throw new TypeError('Invalid Number of arguments, expected at least 1');
          }
          if (hash.interval) {
            runLater(this, function () {
              this.recompute();
            }, hash.interval);
          }
          return moment['default'].apply(this, params).fromNow();
        }
      });
    } else {
      ago = function (params) {
        if (typeof cb === 'function') {
          cb();
        }
        if (params.length === 0) {
          throw new TypeError('Invalid Number of arguments, expected at least 1');
        }
        return moment['default'].apply(this, params).fromNow();
      };
    }
    return ago;
  };

  exports['default'] = helperFactory();

  exports.helperFactory = helperFactory;

});