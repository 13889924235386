define('ember-moment/helpers/moment-format', ['exports', 'moment'], function (exports, moment) {

  'use strict';

  function momentHelper(params) {
    var length = params.length;
    var args = [];
    var output = undefined;

    if (length === 0 || length > 3) {
      throw new TypeError('Invalid Number of arguments, expected at least 1 and at most 3');
    }

    args.push(params[0]);

    if (length === 1) {
      output = 'LLLL';
    } else if (length === 2) {
      output = params[1];
    } else if (length > 2) {
      args.push(params[2]);
      output = params[1];
    }

    return moment['default'].apply(this, args).format(output);
  }

  exports['default'] = momentHelper;

});